<template>
    <div id="playSong" class="vh-100">
        <div class="position-absolute start-50 top-50 translate-middle text-center h3 w-100 lh-lg text-white">
            <div class="display-1 mb-4 animate__animated animate__fadeInDown">
                Score<br>
                <h1 class="score ">{{this.score}}</h1>
            </div>
            <p v-html="ment" class="animate__animated animate__fadeInDown 
            animate__delay-1s"></p>
        </div>
        <small class="d-flex w-100 justify-content-between text-white position-absolute bottom-0 p-3"><span>{{ count }}초 뒤 메인 화면으로 이동합니다.</span> <router-link to="/" v-if="show ==true">>> SKIP</router-link></small>
    </div>
</template>

<script>
const CryptoJS = require("crypto-js");
let interval;
export default {
    data(){
        return{
            play_code : this.$route.params.code,
            score : this.$route.params.score,
            time : this.$route.params.time,
            count: 5,
            show : false
        }
    },
    computed:{
        ment(){
            if(this.score === 100){
                return '축하합니다. <br>새로운 가수 탄생!'
            }else if(this.score < 100 && this.score >=80){
                return '훌륭해요!<br>대단한 실력이네요!'
            }else if(this.score < 80 && this.score >=60){
                return '멋진 노래였어요.<br>한 곡 더!'
            }else{
                return '좀 더 연습해서<br>다시 도전해 볼까요?'
            }
        },
    },
    mounted(){
        this.SetPlayEnd();
        

        this.$EventBus.$emit('HideHeader')
        this.$EventBus.$emit('HideNav')
    },
    methods:{
        SetPlayEnd(){
            const play_code = this.play_code;
            const score = this.score;
            const time = this.time;
            
            const body = {play_code,score,time};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.loading = true;
            this.$http.post('/front/common/PlaySongEnd',{req}).then(
                (res) =>  { 
                    if(res.status == 200){
                        this.autocomplete = false;
                        this.loading = false;
                        if(res.data.code =="200"){
                            this.show = true;
                            interval = setInterval(() => {
                                this.count--;
                                if( this.count <= 0 ){
                                    clearInterval(interval);
                                    this.$router.replace('/');
                                }
                            }, 1000);
                        } else if (res.data.code == '9999') {
                            this.$store.dispatch('SETLOGOUT').then(() => {
                                this.$router.push({ path: '/signin' });
                            });
                        }
                    }
                }
            );
        }
    },
    destroyed(){
        this.$EventBus.$emit('ShowHeader')
        this.$EventBus.$emit('ShowNav')
        clearInterval(interval);
    }
}
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css');
#playSong{
    background-image: url('../../assets/img/score.png');
    // background-size: cover;
    background-size: auto;
    background-repeat: no-repeat;
    animation: background-moving 30s linear forwards;
    text-shadow: 0 0 7px #fff, 0 0 10px #3f3597, 0 0 21px #191858, 0 0 42px #50bbff, 0 0 82px #0058ff, 0 0 92px #0054bd, 0 0 102px #0089ff, 0 0 151px #0800ff;
}
.score{
    font-family: 'Lobster', cursive;
    font-size: 2em;
    font-weight: 600;
}
@keyframes background-moving {
    from{
        background-position: 0%;
    }
    to{
        background-position: center;
    }
}
</style>